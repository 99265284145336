.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.frame-enter {
  position: absolute;
  opacity: 0;
}
.frame-enter-active {
  opacity: 1;
  transition: opacity .2s;
}
.frame-exit {
  position: absolute;
  opacity: 1;
}
.frame-exit-active {
  opacity: 0;
  transition: opacity .2s;
}
